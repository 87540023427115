import { FoundContent } from '@/components/Css/FoundSection';
import {
  Render,
  Article,
  ChartContainer,
  TableContainer,
  NoticeBox,
} from '@/components/Css/ReportKorea';
import DonutChartComponent from '@/components/DonutChart';
import DonutChartMobileComponent from '@/components/DonutChartMobile';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import React from 'react';

const ReportUnicef2020: React.FC = () => (
  <Render>
    <FoundContent>
      <Section>
        <SectionHeader>
          <h2>
            <Tit size="s1">유니세프 예산 구성비</Tit>
          </h2>
        </SectionHeader>

        <Article>
          <h3 className="sky-bd">
            <Tit size="s2" color="sky">
              2020 수입
            </Tit>
          </h3>
          <div className="m-hide">
            <ChartContainer>
              <DonutChartComponent
                centerText="<span style='font-size: 20px; color:#1cabe2;'>총 수입</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#1cabe2'>75억 4,800만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                series={[
                  {
                    name: ``,
                    colorByPoint: true,
                    innerSize: `65%`,
                    type: `pie`,
                    data: [
                      { name: `각국 정부 지원금`, y: 65.3 },
                      { name: `민간 및 비정부 기구 지원금`, y: 21.3 },
                      { name: `기구간 조정`, y: 10.2 },
                      { name: `기타(이자수익 등)`, y: 3.2 },
                    ],
                  },
                ]}
              />
            </ChartContainer>
          </div>
          <div className="pc-hide">
            <ChartContainer>
              <DonutChartMobileComponent
                titleText="<span style='font-size: 16px;color:#1cabe2;'>총 수입</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>75억 4,800만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                series={[
                  {
                    name: ``,
                    colorByPoint: true,
                    innerSize: `50%`,
                    type: `pie`,
                    data: [
                      { name: `각국 정부 지원금`, y: 65.3 },
                      { name: `민간 및 비정부 기구 지원금`, y: 21.3 },
                      { name: `기구간 조정`, y: 10.2 },
                      { name: `기타(이자수익 등)`, y: 3.2 },
                    ],
                  },
                ]}
              />
            </ChartContainer>
          </div>
          <TableContainer>
            <table className="tbl1">
              <colgroup>
                <col className="col1" />
                <col className="col2" />
                <col className="col3" />
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>금액(백만 달러)</th>
                  <th>비중</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>각국 정부 지원금</td>
                  <td>
                    <p className="price">4,929</p>
                  </td>
                  <td>
                    <p className="price">65.3%</p>
                  </td>
                </tr>
                <tr>
                  <td>민간 및 비정부 기구 지원금</td>
                  <td>
                    <p className="price">1,610</p>
                  </td>
                  <td>
                    <p className="price">21.3%</p>
                  </td>
                </tr>
                <tr>
                  <td>기구간 조정*</td>
                  <td>
                    <p className="price">767</p>
                  </td>
                  <td>
                    <p className="price">10.2%</p>
                  </td>
                </tr>
                <tr>
                  <td>기타(이자수익 등)</td>
                  <td>
                    <p className="price">242</p>
                  </td>
                  <td>
                    <p className="price">3.2%</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>계</strong>
                  </td>
                  <td>
                    <p className="price">7,548</p>
                  </td>
                  <td>
                    <p className="price">100%</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
          <NoticeBox>
            <ul className="blit-list">
              <li>
                <strong>기구간 조정 : </strong>유니세프를 포함한 여러 국제기구가
                관리하는 유엔 협력 사업비 중 유니세프 분담액
              </li>
            </ul>
          </NoticeBox>
        </Article>

        <Article>
          <h3 className="sky-bd">
            <Tit size="s2" color="sky">
              2020 지출
            </Tit>
          </h3>

          <div className="tbl-wrap">
            <div className="m-hide">
              <ChartContainer>
                <Tit size="s3-1">총지출 구성비</Tit>
                <DonutChartComponent
                  centerText="<span style='font-size: 20px;color:#1cabe2;'>총 지출</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#1cabe2'>65억 3,500만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `65%`,
                      type: `pie`,
                      data: [
                        { name: `사업비`, y: 89.4 },
                        { name: `행정관리비`, y: 5.9 },
                        { name: `민간 및 파트너십 모금비`, y: 3.1 },
                        { name: `기타`, y: 1.3 },
                        {
                          name: `특별비 (보안, 정보 통신 투자비 등)`,
                          y: 0.2,
                        },
                        { name: `유엔개발협력비`, y: 0.2 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <div className="pc-hide">
              <ChartContainer>
                <DonutChartMobileComponent
                  titleText="<span style='font-size: 16px;color:#1cabe2;'>총 지출</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>65억 3,500만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `50%`,
                      type: `pie`,
                      data: [
                        { name: `사업비`, y: 89.4 },
                        { name: `행정관리비`, y: 5.9 },
                        { name: `민간 및 파트너십 모금비`, y: 3.1 },
                        { name: `기타`, y: 1.3 },
                        {
                          name: `특별비 (보안, 정보 통신 투자비 등)`,
                          y: 0.2,
                        },
                        { name: `유엔개발협력비`, y: 0.2 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <TableContainer>
              <table className="tbl1">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                  <col className="col3" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>금액(백만 달러)</th>
                    <th>비중</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>사업비</td>
                    <td>
                      <p className="price">5,841</p>
                    </td>
                    <td>
                      <p className="price">89.4%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>행정관리비</td>
                    <td>
                      <p className="price">385</p>
                    </td>
                    <td>
                      <p className="price">5.9%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>민간 및 파트너십 모금비</td>
                    <td>
                      <p className="price">203 </p>
                    </td>
                    <td>
                      <p className="price">3.1%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>기타*</td>
                    <td>
                      <p className="price">82</p>
                    </td>
                    <td>
                      <p className="price">1.3%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>특별비 (보안, 정보 통신 투자비 등)</td>
                    <td>
                      <p className="price">12</p>
                    </td>
                    <td>
                      <p className="price">0.2%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>유엔개발협력비</td>
                    <td>
                      <p className="price">12</p>
                    </td>
                    <td>
                      <p className="price">0.2%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>계</strong>
                    </td>
                    <td>
                      <p className="price">6,535</p>
                    </td>
                    <td>
                      <p className="price">100%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
            <NoticeBox>
              <ul className="blit-list">
                <li>
                  <strong>기타 : </strong>각 국가위원회의 모금 및 아동 권리 증진
                  활동을 돕는 투자비, 구호 물품 조달비 등
                </li>
              </ul>
            </NoticeBox>
          </div>

          <div className="tbl-wrap">
            <div className="m-hide">
              <ChartContainer>
                <Tit size="s3-1">분야별 직접 사업비</Tit>
                <DonutChartComponent
                  centerText="<span style='font-size: 20px;color:#1cabe2;'>분야별 직접 사업비</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#1cabe2'>57억 1,500만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `65%`,
                      type: `pie`,
                      data: [
                        { name: `생존`, y: 38.2 },
                        { name: `교육`, y: 20.4 },
                        { name: `환경`, y: 19.6 },
                        { name: `보호`, y: 12.5 },
                        { name: `공평한 기회 보장`, y: 9.3 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <div
              className="pc-hide"
              css={`
                margin-top: 96px;
              `}
            >
              <ChartContainer>
                <DonutChartMobileComponent
                  titleText="<span style='font-size: 16px;color: #1cabe2;'>분야별 직접 사업비</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>57억 1,500만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `50%`,
                      type: `pie`,
                      data: [
                        { name: `생존`, y: 38.2 },
                        { name: `교육`, y: 20.4 },
                        { name: `환경`, y: 19.6 },
                        { name: `보호`, y: 12.5 },
                        { name: `공평한 기회 보장`, y: 9.3 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <TableContainer>
              <table className="tbl1">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                  <col className="col3" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>금액(백만 달러)</th>
                    <th>비중</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>생존</td>
                    <td>
                      <p className="price">2,181.4</p>
                    </td>
                    <td>
                      <p className="price">38.2%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>교육</td>
                    <td>
                      <p className="price">1,167.3</p>
                    </td>
                    <td>
                      <p className="price">20.4%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>환경</td>
                    <td>
                      <p className="price">1,121.8</p>
                    </td>
                    <td>
                      <p className="price">19.6%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>보호</td>
                    <td>
                      <p className="price">712.0</p>
                    </td>
                    <td>
                      <p className="price">12.5%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>공평한 기회 보장</td>
                    <td>
                      <p className="price">532.6</p>
                    </td>
                    <td>
                      <p className="price">9.3%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>계</strong>
                    </td>
                    <td>
                      <p className="price">5,715.0</p>
                    </td>
                    <td>
                      <p className="price">100%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
          </div>

          <div className="tbl-wrap">
            <div className="m-hide">
              <ChartContainer>
                <Tit size="s3-1">지역별 직접 사업비</Tit>
                <DonutChartComponent
                  centerText="<span style='font-size: 20px;color:#1cabe2;'>지역별 직접 사업비</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#1cabe2'>57억 1,500만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `65%`,
                      type: `pie`,
                      data: [
                        { name: `사하라 이남 아프리카`, y: 42.5 },
                        { name: `중동/북아프리카`, y: 25.7 },
                        { name: `아시아`, y: 17.2 },
                        { name: `유럽/중앙아시아`, y: 5.8 },
                        { name: `라틴아메리카/카리브해`, y: 5.1 },
                        { name: `지역간 조정`, y: 3.6 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <div
              className="pc-hide"
              css={`
                margin-top: 96px;
              `}
            >
              <ChartContainer>
                <DonutChartMobileComponent
                  titleText="<span style='font-size: 16px;color:#1cabe2;'>지역별 직접 사업비</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>57억 1,500만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `50%`,
                      type: `pie`,
                      data: [
                        { name: `사하라 이남 아프리카`, y: 42.5 },
                        { name: `중동/북아프리카`, y: 25.7 },
                        { name: `아시아`, y: 17.2 },
                        { name: `유럽/중앙아시아`, y: 5.8 },
                        { name: `라틴아메리카/카리브해`, y: 5.1 },
                        { name: `지역간 조정`, y: 3.6 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <TableContainer>
              <table className="tbl1">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                  <col className="col3" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>금액(백만 달러)</th>
                    <th>비중</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>사하라 이남 아프리카</td>
                    <td>
                      <p className="price">2,429.7</p>
                    </td>
                    <td>
                      <p className="price">42.5%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>중동/북아프리카</td>
                    <td>
                      <p className="price">1,469.9</p>
                    </td>
                    <td>
                      <p className="price">25.7%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>아시아</td>
                    <td>
                      <p className="price">984.6</p>
                    </td>
                    <td>
                      <p className="price">17.2%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>유럽/중앙아시아</td>
                    <td>
                      <p className="price">332.9</p>
                    </td>
                    <td>
                      <p className="price">5.8%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>라틴아메리카/카리브해</td>
                    <td>
                      <p className="price">291.5</p>
                    </td>
                    <td>
                      <p className="price">5.1%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>지역간 조정</td>
                    <td>
                      <p className="price">206.6</p>
                    </td>
                    <td>
                      <p className="price">3.6%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>계</strong>
                    </td>
                    <td>
                      <p className="price">5,715.0</p>
                    </td>
                    <td>
                      <p className="price">100%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>

            <NoticeBox>
              <ul className="blit-list">
                <li>
                  유니세프 지출예산은 당해년도 회기 1~2년 전에 집행이사회로부터
                  승인을 받습니다. <br />
                  이로 인해 당해년도의 수입과 지출의 차이가 발생하며, 당해년도
                  차액은 다음 해의 프로그램 예산에 반영됩니다.
                </li>
                <li>
                  각 구분 금액의 합은 반올림에 의해 총 액과 다를 수 있습니다.
                </li>
                <li>
                  출처 :&nbsp;
                  <a
                    href="https://www.unicef.org/reports/unicef-annual-report-2020"
                    className="link-blank"
                    target="_balnk"
                  >
                    &nbsp;UNICEF Annual Report 2020
                  </a>
                </li>
              </ul>
            </NoticeBox>
          </div>
        </Article>
      </Section>
    </FoundContent>
  </Render>
);
export default ReportUnicef2020;
