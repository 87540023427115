import FundUnicefTemplate from '@/page-blocks/about-us/clear/fund/unicef/FundUnicefTemplate';
import ReportUnicef2020 from '@/page-blocks/about-us/clear/fund/unicef/report.unicef_2020';
import { PageProps } from 'gatsby';
import React from 'react';

const FundUnicef2020: React.FC<PageProps> = (props) => (
  <FundUnicefTemplate year={2020} {...props}>
    <ReportUnicef2020 />
  </FundUnicefTemplate>
);

export default FundUnicef2020;